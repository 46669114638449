import React, { Component } from 'react';
import { $ } from 'react-jquery-plugin'
import Typewriter from "typewriter-effect";


class Home extends Component {


    componentDidMount() {
        // Header carousel
        $(".header-carousel").owlCarousel({
            autoplay: false,
            animateOut: 'fadeOutLeft',
            items: 1,
            dots: false,
            loop: true,
            nav: true,
            navText: [
                '<i class="bi bi-chevron-left"></i>',
                '<i class="bi bi-chevron-right"></i>'
            ]
        });
    }
    render() {
        return (
            <>
                <div class="container-fluid header bg-primary  p-0 mb-5">
                    <div class="row g-0 align-items-center flex-column-reverse flex-lg-row">
                        <div class="col-lg-6 p-5 wow fadeIn" data-wow-delay="0.1s">
                            {/* <h1 class="display-4 text-white mb-5">Good Health Is The Root Of All Heppiness</h1>
                            <div class="row g-4">
                                <div class="col-sm-4">
                                    <div class="border-start border-light ps-4">
                                        <h2 class="text-white mb-1" data-toggle="counter-up">123</h2>
                                        <p class="text-light mb-0">Expert Doctors</p>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="border-start border-light ps-4">
                                        <h2 class="text-white mb-1" data-toggle="counter-up">1234</h2>
                                        <p class="text-light mb-0">Medical Stuff</p>
                                    </div>
                                </div>
                                <div class="col-sm-4">
                                    <div class="border-start border-light ps-4">
                                        <h2 class="text-white mb-1" data-toggle="counter-up">12345</h2>
                                        <p class="text-light mb-0">Total Patients</p>
                                    </div>
                                </div>
                            </div> */}

                            <h1 className='homeText'>
                                <Typewriter
                                    onInit={(typewriter) => {
                                        typewriter
                                            .typeString("ZQ Aesthetic Clinic & Hijama Centre")
                                            .pauseFor(1000)
                                            .deleteAll()
                                            .typeString("Welcomes You")
                                            .start();
                                    }} />
                            </h1>

                        </div>
                        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <div class="owl-carousel header-carousel">
                                <div class="owl-carousel-item position-relative">
                                    <img class="img-fluid" src="img/skin.jpeg" alt="" />
                                    <div class="owl-carousel-text">
                                        <h1 class="display-1 text-white mb-0">Skin</h1>
                                    </div>
                                </div>
                                <div class="owl-carousel-item position-relative">
                                    <img class="img-fluid" src="img/hair.jpg" alt="" />
                                    <div class="owl-carousel-text">
                                        <h1 class="display-1 text-white mb-0">Hair</h1>
                                    </div>
                                </div>
                                <div class="owl-carousel-item position-relative">
                                    <img class="img-fluid" src="img/laser.jpeg" alt="" />
                                    <div class="owl-carousel-text">
                                        <h1 class="display-1 text-white mb-0">Laser</h1>
                                    </div>
                                </div>
                                <div class="owl-carousel-item position-relative">
                                    <img class="img-fluid" src="img/hijama.jpeg" alt="" />
                                    <div class="owl-carousel-text">
                                        <h1 class="display-1 text-white mb-0">Hijama</h1>
                                    </div>
                                </div>
                                <div class="owl-carousel-item position-relative">
                                    <img class="img-fluid" src="img/naturopathy.jpeg" alt="" />
                                    <div class="owl-carousel-text">
                                        <h1 class="display-1 text-white mb-0">NATUROPATHY</h1>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Home;
