import React, { Component } from 'react';
import { $ } from 'react-jquery-plugin'
import { Route, Link, BrowserRouter, Switch } from 'react-router-dom'
import Swal from 'sweetalert2'


export default class Header extends Component {
    componentDidMount() {
        // Spinner
        var spinner = function () {
            setTimeout(function () {
                if ($('#spinner').length > 0) {
                    $('#spinner').removeClass('show');
                }
            }, 1);

        };
        spinner();

        // Initiate the wowjs

        // Testimonials carousel
        $(".testimonial-carousel").owlCarousel({
            autoplay: false,
            smartSpeed: 1000,
            center: true,
            dots: false,
            loop: true,
            nav: false,

            responsive: {
                0: {
                    items: 1
                },
                768: {
                    items: 2
                }
            }
        });

        // Sticky Navbar
        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {
                $('.sticky-top').addClass('shadow-sm').css('top', '0px');
            } else {
                $('.sticky-top').removeClass('shadow-sm').css('top', '-100px');
            }
        });


        // Back to top button
        $(window).scroll(function () {
            if ($(this).scrollTop() > 300) {
                $('.back-to-top').fadeIn('slow');
            } else {
                $('.back-to-top').fadeOut('slow');
            }
        });
        $('.back-to-top').click(function () {
            $('html, body').animate({ scrollTop: 0 }, 1500, 'easeInOutExpo');
            return false;
        });



        // Facts counter
        // $('[data-toggle="counter-up"]').counterUp({
        //     delay: 10,
        //     time: 2000
        // });


        // Date and time picker
        // $('.date').datetimepicker({
        //     format: 'L'
        // });
        // $('.time').datetimepicker({
        //     format: 'LT'
        // });


        // Header carousel
        $(".header-carousel").owlCarousel({
            autoplay: false,
            animateOut: 'fadeOutLeft',
            items: 1,
            dots: false,
            loop: true,
            nav: true,
            navText: [
                '<i class="bi bi-chevron-left"></i>',
                '<i class="bi bi-chevron-right"></i>'
            ]
        });

    }


    render() {

        const callNow = () => {
            Swal.fire("<small><a href='tel: +918828385140'>+91 8828385140</a>  <br/><a href='tel: +918828458940'>+91 8828458940</a></small>");

        }
        const getDirections = () => {
            window.open("https://maps.app.goo.gl/Dxp4vrvcwy2FGBab6", '_blank')
        }

        return (

            <>
                <div class="container-fluid bg-light p-0 wow fadeIn" data-wow-delay="0.1s">
                    <div class="row gx-0  d-lg-flex">
                        <div class="col-lg-7 d-none col-0  px-5 text-start">
                            <div class="h-100  d-none d-lg-flex d-inline-flex align-items-center py-3 me-4">
                                <small class="fa fa-map-marker-alt text-primary me-2"></small>
                                <small>Naya Nagar, Mira Road East, Mira Bhayandar</small>
                            </div>
                            {/* <div class="h-100 d-inline-flex align-items-center py-3">
                                <small class="far fa-clock text-primary me-2"></small>
                                <small>Mon - Fri : 09.00 AM - 09.00 PM</small>
                            </div> */}
                        </div>
                        <div class="col-lg-12    text-end">
                            <div class="h-100 d-inline-flex align-items-center py-3 me-4" >
                                <button id='callNow-btn' onClick={callNow}><small class="fa fa-phone-alt text-primary me-2"></small>
                                    <small>Call now</small></button>
                            </div>
                            <div class="h-100 d-inline-flex align-items-center py-3 me-4" >
                                <button id='callNow-btn' onClick={getDirections}><small class="fa fa-map-marker-alt text-primary me-2"></small>
                                    <small>Get Directions</small></button>
                            </div>
                            {/* <div class="h-100 d-inline-flex align-items-center">
                                <a class="btn btn-sm-square rounded-circle bg-white text-primary me-1" href=""><i
                                    class="fab fa-facebook-f"></i></a>
                                <a class="btn btn-sm-square rounded-circle bg-white text-primary me-1" href=""><i
                                    class="fab fa-twitter"></i></a>
                                <a class="btn btn-sm-square rounded-circle bg-white text-primary me-1" href=""><i
                                    class="fab fa-linkedin-in"></i></a>
                                <a class="btn btn-sm-square rounded-circle bg-white text-primary me-0" href=""><i
                                    class="fab fa-instagram"></i></a>
                            </div> */}
                        </div>
                    </div>
                </div>


                <nav class="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 wow fadeIn" data-wow-delay="0.1s">
                    <a href="" class="navbar-brand d-flex align-items-center px-4 px-lg-5">
                        {/* <h1 class="m-0 text-primary"><i class="far fa-hospital me-3"></i>Klinik</h1> */}
                        <div className="logo" style={{ height: '100%' }}>
                            <img src="../img/logo.png" alt="" />
                            <div className="names">
                                <p>AESTHETIC CLINIC  </p>
                                <hr />
                                <p>
                                    & HIJAMA CENTRE
                                </p>
                            </div>
                        </div>
                    </a>
                    <button type="button" class="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span class="navbar-toggler-icon"></span>
                    </button>
                    <div class="collapse navbar-collapse" id="navbarCollapse">
                        <div class="navbar-nav ms-auto p-4 p-lg-0">
                            <a href="" class="nav-item nav-link active">Home</a>
                            <a href="#about" class="nav-item nav-link">About</a>
                            <a href="#service" class="nav-item nav-link">Service</a>
                            <a href="#contact" class="nav-item nav-link">Contact</a>


                        </div>


                    </div >
                    <a href="#appointment" class="btn btn-primary rounded-0 py-4 px-lg-5  d-lg-block" id='appointment_btn'>Appointment<i
                        class="fa fa-arrow-right ms-3"></i></a>
                </nav ></>
        )
    }
}
