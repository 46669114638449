import React from 'react'
import Contact from './Contact'

function Footer() {
    function myGreeting() {
        document.querySelector('#banner_modal').click()
    }
    // const banner_modal =
    // setTimeout(myGreeting, 5000);

    return (
        <>
            <Contact />
            <button type="button" id='banner_modal' style={{ display: 'none' }} class="btn btn-primary" data-toggle="modal" data-target=".exampleModalCenter">Large modal</button>

            <div class="modal fade exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
                <div class="modal-dialog modal-dialog-centered">
                    <div class="modal-content">
                        <div class="modal-header">
                            <h5 class="modal-title">discount offers</h5>
                            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div class="owl-carousel header-carousel d-none">
                            <div class="owl-carousel-item position-relative">
                                <img class="img-fluid" src="img/skin.jpeg" alt="" />
                                <div class="owl-carousel-text">
                                    {/* <h1 class="display-1 text-white mb-0">Skin</h1> */}
                                </div>
                            </div>
                            <div class="owl-carousel-item position-relative">
                                <img class="img-fluid" src="img/hair.jpg" alt="" />
                                <div class="owl-carousel-text">
                                    {/* <h1 class="display-1 text-white mb-0">Hair</h1> */}
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <div class="container-fluid bg-dark text-light footer  wow fadeIn" data-wow-delay="0.1s">

                <div class="container">
                    <div class="copyright">
                        <div class="row">
                            <div class="col-md-6 text-center text-md-start mb-3 mb-md-0">
                                &copy; <a class="border-bottom" href="#">www.zqaestheticandhijama.com</a>, All Right Reserved.
                            </div>
                            <div class="col-md-6 text-center text-md-end">
                                Build By <a class="border-bottom" href="https://linktr.ee/asmerchougle">Asmer Chougle</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footer