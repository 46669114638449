import React from 'react'

function Features() {
    return (
        <>
            <div class="container-fluid bg-primary overflow-hidden my-5 px-lg-0">
                <div class="container feature px-lg-0">
                    <div class="row g-0 mx-lg-0">
                        <div class="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.1s">
                            <div class="p-lg-5 ps-lg-0">
                                <p class="d-inline-block border rounded-pill text-light py-1 px-4">Features</p>
                                <h1 class="text-white mb-4">Why Choose Us</h1>
                                <p class="text-white mb-4 pb-2">we offer a comprehensive range of services, ensuring a holistic approach to health. At our clinic, you'll find a welcoming environment where your concerns are heard, and your problem is our priority. </p>
                                <div class="row g-4">
                                    <div class="col-6">
                                        <div class="d-flex align-items-center">
                                            <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light"
                                                id='feature-box-style'>
                                                <i class="fa fa-user-md text-primary"></i>
                                            </div>
                                            <div class="ms-4">
                                                <p class="text-white mb-2">Experience</p>
                                                <h5 class="text-white mb-0">Doctors</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="d-flex align-items-center">
                                            <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light"
                                                id='feature-box-style'>
                                                <i class="fa fa-check text-primary"></i>
                                            </div>
                                            <div class="ms-4">
                                                <p class="text-white mb-2">Quality</p>
                                                <h5 class="text-white mb-0">Services</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="d-flex align-items-center">
                                            <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-light"
                                                id='feature-box-style'>
                                                <i class="fa fa-comment-medical text-primary"></i>
                                            </div>
                                            <div class="ms-4">
                                                <p class="text-white mb-2">Positive</p>
                                                <h5 class="text-white mb-0">Consultation</h5>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 pe-lg-0 wow fadeIn" data-wow-delay="0.5s" style={{ minHeight: '400px' }}>
                            <div class="position-relative h-100">
                                <img class="position-absolute img-fluid w-100 h-100" src="img/about-3.jpg"
                                    style={{ objectFit: 'cover' }} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Features