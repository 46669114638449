import logo from './logo.svg';
import './App.css';
import Header from './components/Header';
import Home from './components/Home';
import About from './components/About';
import Service from './components/Service';
import Features from './components/Features';
import Doctors from './components/Doctors';
import Appointment from './components/Appointment';
import Testimonals from './components/Testimonals';
import Footer from './components/Footer';

function App() {
  return (
    <>
      <Header />
      <Home />
      <About />
      <Service />
      <Features />
      <Doctors />
      <Appointment />
      {/* <Testimonals /> */}

      <Footer />
    </>
  );
}

export default App;
