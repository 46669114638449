

import React from 'react'
import Swal from 'sweetalert2'


// const Swal = require('sweetalert2')

function Appointment() {
    // let form = document.querySelector("form");
    // form.addEventListener('submit', (e) => {
    //     e.preventDefault();
    //     let data = new FormData(form);
    // })
    let form = document.querySelector('form');
    const handle_submit = (e) => {
        appointmentSending();
        e.preventDefault();

        let form = document.querySelector('form');


        const data = Array.from(e.target.elements)
            .filter((input) => input.name)
            .reduce((obj, input) => Object.assign(obj, { [input.name]: input.value }), {});
        // let data = new FormData(form);
        // console.log(data.name)




        fetch('https://sheetdb.io/api/v1/86uufervb9tbn', {

            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',

            },
            body: JSON.stringify({
                data: data
            })
        })
            .then((response) => response.json())
            .then(appointmentAdded)
            .then(console.log(data))
            .catch((error) => console.log(error))
        form.reset()
    }
    const appointmentSending = () => {
        let timerInterval;
        Swal.fire({
            title: "Checking for Appointment Availability",
            html: "Sending...  ",
            timer: 10000,
            timerProgressBar: true,
            // didOpen: () => {
            //     Swal.showLoading();
            //     const timer = Swal.getPopup().querySelector("b");
            //     timerInterval = setInterval(() => {
            //         timer.textContent = `${Swal.getTimerLeft()}`;
            //     }, 100);
            // },
            willClose: () => {
                clearInterval(timerInterval);
            }
        }).then((result) => {
            /* Read more about handling dismissals below */
            if (result.dismiss === Swal.DismissReason.timer) {
                // console.log("I was closed by the timer");
                Swal.fire({
                    title: "Failed",
                    icon: "error"
                });
            }
        });

    }
    const appointmentAdded = () => {
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: "btn btn-primary mx-2",
                cancelButton: "btn btn-secondary "
            },
            buttonsStyling: false
        });
        swalWithBootstrapButtons.fire({
            title: "Appointment is set",

            icon: "success",
            showCancelButton: true,
            confirmButtonText: "Get Connected with Doctors",
            cancelButtonText: "No, Thanks!",
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                swalWithBootstrapButtons.fire({
                    title: "Get Connected with Our Doctors",
                    confirmButtonText: "<a href='https://api.whatsapp.com/send/?phone=8828385140&text=Hello My appointment book'>Whatsapp msg</a>",
                    icon: "success"
                });
            }
        });
    }


    return (
        <>
            <div class="container-xxl py-5" id="appointment">
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
                            <p class="d-inline-block border rounded-pill py-1 px-4">Appointment</p>
                            <h1 class="mb-4">Make An Appointment To Visit Our Doctor</h1>
                            <p class="mb-4">
                            </p>
                            <div class="bg-light rounded d-flex align-items-center p-5 mb-4">
                                <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"
                                    id='feature-box-style'>
                                    <i class="fa fa-phone-alt text-primary"></i>
                                </div>
                                <div class="ms-4">
                                    <p class="mb-2">Call Us Now</p>
                                    <h5 class="mb-0"><a href="tel:+918828385140">+91 8828385140</a> <br /> <a href="tel:+918828458940">+91 8828458940</a></h5>
                                </div>
                            </div>

                            <div class="bg-light rounded d-flex align-items-center p-5">
                                <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"
                                    id='feature-box-style'>
                                    <i class="fa fa-envelope-open text-primary"></i>
                                </div>
                                <div class="ms-4">
                                    <p class="mb-2">Mail Us Now</p>
                                    <h5 class="mb-0"><a href="mailto:info@zqaestheticandhijama.com<">info@zqaestheticand<br></br>hijama.com</a></h5>
                                    {/* <button onClick={appointmentAdded}>checking...</button> */}

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
                            <div class="bg-light rounded h-100 d-flex align-items-center p-5">
                                <form onSubmit={handle_submit} method='POST'>
                                    <div class="row g-3">
                                        <div class="col-12 col-sm-6">
                                            <input required type="text" class="form-control border-0" placeholder="Your Name*"
                                                id='input-appointment' name='name' />
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <input type="email" class="form-control border-0" placeholder="Your Email"
                                                id='input-appointment' name='email' />
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <input required type="text" class="form-control border-0" placeholder="Your Mobile*"
                                                id='input-appointment' name='phone' />
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <select class="form-select border-0" required id='input-appointment' name='doctor'>
                                                <option selected>Choose Treatment*</option>
                                                <option value="Skin">Skin</option>
                                                <option value="Hair">Hair</option>
                                                <option value="Laser">Laser</option>
                                                <option value="Hijama">Hijama</option>
                                                <option value="Weight Management">Weight Management</option>
                                                <option value="Naturopathy">Naturopathy</option>
                                                <option value="Stretch Marks">Stretch Marks</option>
                                            </select>
                                        </div>
                                        <div class="col-12 col-sm-6">
                                            <div class="date" id="" data-target-input="nearest">
                                                <input required type="date" name='date' class="form-control border-0 datetimepicker-input"
                                                    placeholder="Choose Date*" data-target="#date" data-toggle="datetimepicker"
                                                    id='input-appointment' />
                                            </div>

                                        </div>

                                        <div class="col-12 col-sm-6">
                                            <div class="time" id="" data-target-input="nearest">
                                                <input required type="time" name='time' class="form-control border-0 datetimepicker-input"
                                                    placeholder="Choose Time*" data-target="#time" data-toggle="datetimepicker"
                                                    id='input-appointment' />
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <textarea class="form-control border-0" name='treatment' rows="5"
                                                placeholder="Describe your problem"></textarea>
                                        </div>
                                        {/* <span>* (are compulsory)</span> */}
                                        <div class="col-12">
                                            <button class="btn btn-primary w-100 py-3" type="submit">Book Appointment</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}

export default Appointment