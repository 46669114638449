import React from 'react'

function About() {
    return (
        <>

            <div class="container-xxl py-5" id='about'>
                <div class="container">
                    <div class="row g-5">
                        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <div class="d-flex flex-column">
                                <img class="img-fluid rounded w-75 align-self-end" style={{ height: '', objectFit: 'fill' }} src="img/about-4.jpeg" alt="" />
                                <img class="img-fluid rounded w-50 bg-white pt-3 pe-3" style={{ objectFit: 'cover', marginTop: '-45%' }} src="img/about-1.jpg" alt=""
                                />
                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
                            <p class="d-inline-block border rounded-pill py-1 px-4">About Us</p>
                            <h1 class="mb-4">Why You Should Trust Us? Get Know About Us!</h1>
                            <p>Welcome to ZQ Aesthetic Clinic & Hijama Centre, where beauty meets holistic wellness! Our aesthetic and Hijama clinic is a haven for those seeking a harmonious blend of outer radiance and inner vitality.</p>
                            {/* <p class="mb-4">Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum provident qui animi, deserunt exercitationem soluta consectetur minus beatae nam obcaecati! Tempora minima excepturi quisquam inventore. Error possimus sit quo illum.
                        </p> */}
                            <p><i class="far fa-check-circle text-primary me-3"></i>Quality  care</p>
                            <p><i class="far fa-check-circle text-primary me-3"></i>Reliable Equipment</p>
                            <p><i class="far fa-check-circle text-primary me-3"></i>Proven Medical Professionals</p>

                            <a class="btn btn-primary rounded-pill py-3 px-5 mt-3" href="">Read More</a>
                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}

export default About