import React from 'react'
import Header from './Header'
import Footer from './Footer'

function Contact() {
    return (
        <>
            <div class="container-xxl " id='contact'>
                <div class="container">
                    <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                        <p class="d-inline-block border rounded-pill py-1 px-4">Contact</p>
                        <h1>Contact Details</h1>
                    </div>
                    <div class="row  g-4">
                        <div class="col-lg-4">
                            <div class="h-100 bg-light rounded d-flex align-items-center p-5">
                                <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"
                                    id='feature-box-style'>
                                    <i class="fa fa-map-marker-alt text-primary"></i>
                                </div>
                                <div class="ms-4">
                                    <p class="mb-2">Address</p>
                                    <h5 class="mb-0">Naya Nagar, Mira Road East, Mira Bhayandar</h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="h-100 bg-light rounded d-flex align-items-center p-5">
                                <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"
                                    id='feature-box-style'>
                                    <i class="fa fa-phone-alt text-primary"></i>
                                </div>
                                <div class="ms-4">
                                    <p class="mb-2">Call Us Now</p>
                                    <h5 class="mb-0"><a href="tel:+918828385140">+91 8828385140</a> <br /> <a href="tel:+918828458940">+91 8828458940</a></h5>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="h-100 bg-light rounded d-flex align-items-center p-5">
                                <div class="d-flex flex-shrink-0 align-items-center justify-content-center rounded-circle bg-white"
                                    id='feature-box-style'>
                                    <i class="fa fa-envelope-open text-primary"></i>
                                </div>
                                <div class="ms-4">
                                    <p class="mb-2">Mail Us Now</p>
                                    <h5 class="mb-0"><a href="mailto:info@zqaestheticandhijama.com<">info@zqaestheticand<br></br>hijama.com</a></h5>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
                            <div class="bg-light rounded p-5">
                                <p class="d-inline-block border rounded-pill py-1 px-4">Contact Us</p>
                                <h1 class="mb-4">Have Any Query? Please Contact Us!</h1>

                                <form action='mail.php' method='post'>
                                    <div class="row g-3">
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input type="text" required name='name' class="form-control" id="name" placeholder="Your Name" />
                                                <label for="name">Your Name</label>
                                            </div>
                                        </div>
                                        <div class="col-md-6">
                                            <div class="form-floating">
                                                <input type="email" required name='email' class="form-control" id="email" placeholder="Your Email" />
                                                <label for="email">Your Email</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-floating">
                                                <input type="text" class="form-control" id="subject" placeholder="Subject" />
                                                <label for="subject">Subject</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <div class="form-floating">
                                                <textarea class="form-control" required name='msg' placeholder="Leave a message here" id="message"
                                                    style={{ height: '100px' }}></textarea>
                                                <label for="message">Message</label>
                                            </div>
                                        </div>
                                        <div class="col-12">
                                            <button class="btn btn-primary w-100 py-3" name='submit' type="submit">Send Message</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="col-lg-6 wow fadeIn " data-wow-delay="0.5s">
                            <div class="h-100" style={{ minHeight: '400px' }}>
                                <div class="mapouter">
                                    <div class="gmap_canvas"><iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3765.9459423852045!2d72.85647797521062!3d19.28471688196331!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b1b1ffc46b2b%3A0xb846d9b764a6ca89!2sZQ%20Aesthetic%20Clinic%20%26%20Hijama%20Centre!5e0!3m2!1sen!2sin!4v1700900981132!5m2!1sen!2sin" width="100%" height="80%" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Contact