import React from 'react'

function Service() {

    return (
        <>

            <div class="container-xxl py-5" id='service'>
                <div class="container">
                    <div class="text-center mx-auto mb-5 wow fadeInUp" data-wow-delay="0.1s" style={{ maxWidth: '600px' }}>
                        <p class="d-inline-block border rounded-pill py-1 px-4">Services</p>
                        <h1>Care Solutions</h1>
                    </div>
                    <div class="row g-4" id='flex-wrap-service'>
                        <div class="col-lg-3 col-6 col-md-6 wow fadeInUp text-center" data-wow-delay="0.1s">
                            <div class="service-item bg-light rounded h-100 py-5">
                                <div class="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4"
                                    id='service-box-style'>
                                    <i class="fa fa-smile text-primary fs-4"></i>
                                </div>
                                <h4 class="mb-3">Skin</h4>

                            </div>
                        </div>
                        <div class="col-lg-3 col-6 col-md-6 wow fadeInUp text-center" data-wow-delay="0.3s">
                            <div class="service-item bg-light rounded h-100 py-5">
                                <div class="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4"
                                    id='service-box-style'>
                                    <i class="fa fa-ruler-combined text-primary fs-4"></i>
                                </div>
                                <h4 class="mb-3">Hair</h4>

                            </div>
                        </div>
                        <div class="col-lg-3 col-6 col-md-6 wow fadeInUp text-center" data-wow-delay="0.5s">
                            <div class="service-item bg-light rounded h-100 py-5">
                                <div class="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4"
                                    id='service-box-style'>
                                    <i class="fa fa-x-ray text-primary fs-4"></i>
                                </div>
                                <h4 class="mb-3">Laser</h4>

                            </div>
                        </div>
                        <div class="col-lg-3 col-6 col-md-6 wow fadeInUp text-center" data-wow-delay="0.1s">
                            <div class="service-item bg-light rounded h-100 py-5">
                                <div class="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4"
                                    id='service-box-style'>
                                    <i class="fa fa-vials text-primary fs-4"></i>
                                </div>
                                <h4 class="mb-3">Hijama</h4>

                            </div>
                        </div>
                        <div class="col-lg-3 col-6 col-md-6 wow fadeInUp text-center" data-wow-delay="0.3s">
                            <div class="service-item bg-light rounded h-100 py-5">
                                <div class="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4"
                                    id='service-box-style'>
                                    <i class="fa fa-weight text-primary fs-4"></i>
                                </div>
                                <h4 class="mb-3">Weight Management</h4>
                            </div>
                        </div>
                        <div class="col-lg-3 col-6 col-md-6 wow fadeInUp text-center" data-wow-delay="0.5s">
                            <div class="service-item bg-light rounded h-100 py-5">
                                <div class="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4"
                                    id='service-box-style'>
                                    <i class="fa fa-mortar-pestle text-primary fs-4"></i>
                                </div>
                                <h4 class="mb-3">Naturopathy</h4>

                            </div>
                        </div>
                        <div class="col-lg-3 col-6 col-md-6 wow fadeInUp text-center" data-wow-delay="0.5s">
                            <div class="service-item bg-light rounded h-100 py-5">
                                <div class="d-inline-flex align-items-center justify-content-center bg-white rounded-circle mb-4"
                                    id='service-box-style'>
                                    {/* <i class="fa fa-mortar-pestle text-primary fs-4"></i> */}
                                    <i class="fa fa-male text-primary fs-4"></i>
                                </div>
                                <h4 class="mb-3">Stretch Marks</h4>

                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Service